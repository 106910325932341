<template>
    <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-justify-center"/>
            <strong> Weekly Plan</strong>
            <div class="card-header-actions">
              <a 
                href="#" 
                class="card-header-action" 
                rel="noreferrer noopener" 
                target="_blank"
              >
                <small class="text-muted">Weekly Plan</small>
              </a>
            </div>
          </CCardHeader>
          <CCardBody >
                 <div class="row">
                      <div class="form-group ml-4">
                            <button class="ml-auto mr-3 btn btn-success btn-sm" style="height:27px" @click="addPlan">Add Plan</button>
                      </div>
                      <div class="form-group">
                        <span class="ml-2">From: </span>
                        <input class="ml-2" type="date" v-model="from"/>
                      </div>
                      <div class="form-group">
                        <span class="ml-2">To: </span>
                        <input class="ml-2" type="date" v-model="to"/>
                      </div>
                      <button class="ml-2 btn btn-primary btn-sm" @click="searchLogs()" style="height:27px" v-if="!loading">GENERATE</button>
                      <button class="ml-2 btn btn-success btn-sm" style="height:27px" v-if="preview" @click="previewAR">PREVIEW</button>
                      <button class="ml-2 btn btn-success btn-sm" style="height:27px" v-if="printPreview" @click="unpreviewAR">UNPREVIEW</button>
                      <button class="ml-2 btn btn-primary btn-sm" type="button" disabled v-if="loading" style="height:27px">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Searching...
                      </button>
                      <button class="ml-2 btn btn-primary btn-sm d-flex ml-auto mr-2" style="height:27px" @click="printAR">PRINT</button>
                  </div>
                     <CRow v-if="!printPreview">
                      <CCol lg="12">
                        <weekly-plan :weekly_plans="get_weekly_plans" @updatePlan="openUpdatePlan">
                          <template #header>
                            <!-- <div class="row">
                                <button class="btn btn-success ml-2" @click="addProgramModal" v-if="$store.state.user.role === 'admin' || $store.state.user.role === 'Administrator'">Add Program</button>
                            </div> -->
                            <div class="card-header-actions">
                              <a 
                                href="https://coreui.io/vue/docs/components/nav" 
                                class="card-header-action" 
                                rel="noreferrer noopener" 
                                target="_blank"
                              >
                                <small class="text-muted">Weekly Plan</small>
                              </a>
                            </div>
                          </template>
                        </weekly-plan>
                      </CCol>
                     </CRow>

                  <div id="ar" v-if="printPreview">
                      <div class="container mt-4" >
                        <div class="text-right">
                            <h6>Ref. No. {{reference()}}</h6>
                        </div>
                          <img
                            src="@/assets/logo/header.png"
                            alt=""
                            style="position: absolute; margin-left: 24%; margin-top: -3%; "
                          />
                        <div class="text-center font-weight-bold" style="margin-top: 100px">WEEKLY PLAN</div>
                        <div class="text-center">Month/Year: {{getDate()}}</div>
                        <div>Division: <span class="font-weight-bold"> {{ getDivision($store.state.user.personnel_id) }} </span></div>
                        <div>Unit/Section: <span class="font-weight-bold"> {{ getSection($store.state.user.personnel_id) }} </span></div>

                        <div class="row mt-4" style="border:1px solid; border-bottom: hidden" >
                            <div class="col-md-12 text-center font-weight-bold" style="border:1px solid; border-left: hidden;border-top:hidden;border-right:hidden">ACTIVITIES/TASKS</div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                              <div class="row" >
                                    <div class="col-md-12" style="border: 1px solid; border-top: hidden">
                                      <ul v-for="(get_weekly_plan,i) in get_weekly_plans" :key="i" class="mt-4">
                                          <li>{{ get_weekly_plan.plan }} </li>
                                      </ul>
                                    </div>
                              </div>
                            </div>
                        </div>
                        <div >
                            <div class="row mt-4">
                              <div class="form-group col-md-6">
                                Prepared by: 
                                <div class="font-weight-bold mt-4">{{ getPreparedBy($store.state.user.personnel_id)}}</div>
                                <div>{{getPreparedPosition($store.state.user.personnel_id)}}</div>
                              </div>
                              <div class="form-group col-md-6" v-if="$store.state.user.personnel_id !== 67 && $store.state.user.personnel_id !== 57">
                                  Reviewed<span v-if="$store.state.user.personnel_id === 51">/Noted</span> by: 
                                  <div class="font-weight-bold mt-4">{{getReviewed($store.state.user.personnel_id)}}</div>
                                  <div>{{getReviewedPosition($store.state.user.personnel_id)}}</div>
                                </div>
                                <div class="form-group col-md-6" v-else>
                                  Noted by: 
                                  <div class="font-weight-bold mt-4">{{getNoted($store.state.user.personnel_id)}}</div>
                                  <div>{{getNotedPosition($store.state.user.personnel_id)}}</div>
                                </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                          
                              </div>
                              <div v-if="$store.state.user.personnel_id !== 67 && $store.state.user.personnel_id !== 57 && $store.state.user.personnel_id !== 21 && $store.state.user.personnel_id !== 38">
                                <div class="form-group col-md-12 mt-4" v-if="($store.state.user.role === 'COS' && $store.state.user.personnel_id !== 19 && $store.state.user.personnel_id !== 15 && $store.state.user.personnel_id !== 52 && $store.state.user.personnel_id !== 27) || $store.state.user.role === 'Administrator'">
                                    Noted by: 
                                    <div class="font-weight-bold mt-4">{{getNoted($store.state.user.personnel_id)}}</div>
                                    <div>{{getNotedPosition($store.state.user.personnel_id)}}</div>
                                  </div>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!------------ Start- Add Plan Modal ------------>
    <div id="add-plan" class="modal fade" >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Plan</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitPlan">
            <div class="modal-body">
                <div class="mb-3">
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">Plan</label>
                        <textarea  class="form-control" v-model="ar_plan.plan"/>
                    </div>
                  </div>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button class="btn btn-primary" type="submit" v-if="!loading">Save</button>
              <button class="btn btn-primary" type="button" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Saving...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!------------ End - Add Plan Modal ------------>

   <!------------ Start- Edit Plan Modal ------------>
    <div id="edit-plan" class="modal fade" >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Plan</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitUpdatePlan(editPlan.ar_plan_id)">
            <div class="modal-body">
                <div class="mb-3">
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">Plan</label>
                        <textarea  type="text" class="form-control" v-model="editPlan.plan"/>
                    </div>
                  </div>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button class="btn btn-danger" data-dismiss="modal" @click="deletePlan(editPlan.ar_plan_id)" v-if="!loading1">Delete</button>
              <button class="btn btn-danger" type="button" disabled v-if="loading1">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Deleting...
              </button>
              <button class="btn btn-primary" type="submit" v-if="!loading">Update</button>
              <button class="btn btn-primary" type="button" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Updating...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!------------ End - Edit Plan Modal ------------>

  </div>
</template>

<script>
import VueHtmlToPaper from 'vue-html-to-paper';
import Vue from 'vue'
import WeeklyPlan from './components/WeeklyPlanTable.vue'
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  ]
}
Vue.use(VueHtmlToPaper, options);

export default {
  components: { WeeklyPlan },
  data() {
    return {
      preview: false,
      printPreview: false,
      unpreview: false,
      from: "",
      to: "",
      editPlan: {
        plan: '',
        from_date_plan: '',
        to_date_plan: ''
      },
      loading: false,
      loading1: false,
      ar_plan: {
        plan: '',
        from_date_plan: '',
        to_date_plan: ''
      },
      get_weekly_plans: [],
      regularEmployees: [],
      chedEmployees: [
        { BiometricID: '8', FirstName: 'Kathleen', LastName: 'Castanares', prepared: 'KATHLEEN D. CASTAÑARES', p_position: 'Project Technical Staff I - Budget Assistant', reviewed: 'ALMA S. PATRON, MSW, LLB', r_position: 'Chief Administrative Officer', noted: 'ALMA S. PATRON, MSW, LLB', n_position: 'Chief Administrative Officer'},
        { BiometricID: '9', FirstName: 'Sarah', LastName: 'Moron', prepared: 'SARAH T. MORON', p_position: 'Project Technical Staff I, UniFAST/Accounting Staff', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'Accountant II', noted: 'ALMA SY-PATRON, LLB, MSW', n_position: 'Chief Administrative Officer'},
        { BiometricID: '14', FirstName: 'Calwin', LastName: 'Alegre', prepared: 'JOBERT CALWIN M. ALEGRE', p_position: 'Project Technical Staff III - MIS', reviewed: 'AMY C. SAAVEDRA, MSCA', r_position: 'Education Supervisor II - MIS Head', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '15', FirstName: 'Daisy', LastName: 'Grana', prepared: 'DAISY I. GRANA', p_position: 'Project Technical Staff II, UniFAST', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: '', n_position: ''},
        { BiometricID: '16', FirstName: 'May Trinity', LastName: 'Narca', prepared: 'MAY TRINITY N. DE LA TORRE', p_position: 'Project Technical Staff II, UniFAST/RD Secretary', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: 'ALMA S. PATRON, JD, MSW, RSW', n_position: 'OIC - Director IV'},
        { BiometricID: '17', FirstName: 'Alexis Dave', LastName: 'Sanmiguel', prepared: 'ALEXIS DAVE M. SAN MIGUEL', p_position: 'Project Technical Staff I', reviewed: 'CRISELDO C. CALINAWAN, PHP, PECE', r_position: 'Education Supervisor II', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '18', FirstName: 'Krizia Meijel', LastName: 'Mellijor', prepared: 'KRIZIA MEIJEL MELLIJOR', p_position: 'Project Technical Staff II', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'Accountant II', noted: 'ALMA SY-PATRON, LLB, MSW', n_position: 'Chief Administrative Officer'},
        { BiometricID: '19', FirstName: 'Valerie', LastName: 'Vismanos', prepared: 'VALERIE EDFLO B. VISMANOS', p_position: 'Project Technical Staff I - UniFAST', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: '', n_position: ''},
        { BiometricID: '21', FirstName: 'Heidi', LastName: 'Paradero', prepared: 'HEIDI M. PARADERO', p_position: 'Project Technical Staff II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: 'GEORGE M. COLORADO, PHD, CESO III', n_position: 'Director IV'},
        { BiometricID: '22', FirstName: 'Jo', LastName: 'Orendain', prepared: 'JO SHEILA ORENDAIN', p_position: 'Project Technical Staff I', reviewed: 'REVELYN P. BRINA, MAED', r_position: 'Administrative Officer III', noted: 'ALMA SY-PATRON, LLB, MSW', n_position: 'Chief Administrative Officer'},
        { BiometricID: '23', FirstName: 'Eleazar', LastName: 'Sumalinog', prepared: 'ELEAZAR A. SUMALINOG', p_position: '', reviewed: '', r_position: '', noted: '', n_position: ''},
        { BiometricID: '30', FirstName: 'Ronan', LastName: 'Otaza', prepared: 'RONAN E. OTAZA', p_position: 'Project Technical Staff I - CSP', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '38', FirstName: 'Bill', LastName: 'Baconguis', prepared: 'BILL CARLO R. BACONGUIS', p_position: 'Project Technical Staff II, UniFAST/MIS', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: 'GEORGE M. COLORADO, PHD, CESO III', n_position: 'Director IV'},
        { BiometricID: '39', FirstName: 'Christine', LastName: 'Escol', prepared: 'CHRISTINE R. ESCOL', p_position: 'CSP Support Staff', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '42', FirstName: 'Charles Jem', LastName: 'Montes', prepared: 'CHARLES JEM M. MONTES', p_position: 'Procurement/Admin Staff', reviewed: 'FRANCIS D. ILAGAN', r_position: 'Administrative Officer III - HRMO', noted: 'ALMA SY-PATRON, LLB, MSW', n_position: 'Chief Administrative Officer'},
        { BiometricID: '43', FirstName: 'Trician Faye', LastName: 'Gozon', prepared: 'TRICIAN FAYE D. GOZON', p_position: 'Admin Staff', reviewed: 'REVELYN P. BRINA, MAED', r_position: 'Administrative Officer III', noted: 'GESELLE M. FRANCISCO, CPA', n_position: 'OIC - Chief Administrative Officer'},
        { BiometricID: '46', FirstName: 'Maria Mercedes', LastName: 'Rebita', prepared: 'MA. MERCEDES P. REBITA', p_position: 'Project Technical Staff I - Records Section', reviewed: 'REVELYN P. BRINA, MAED', r_position: 'Administrative Officer III', noted: 'GESELLE M. FRANCISCO, CPA', n_position: 'OIC - Chief Administrative Officer'},
        { BiometricID: '51', FirstName: 'Gladys', LastName: 'Concha', prepared: 'GLADYS O. CONCHA', p_position: 'CHED-COA Support Staff', reviewed: 'JONIFEL T. SAN JUAN, CPA', r_position: 'State Auditor III', noted: 'JONIFEL T. SAN JUAN, CPA', n_position: 'State Auditor III'},
        { BiometricID: '52', FirstName: 'Kershey Reign', LastName: 'Dueñas', prepared: 'KERSHEY REIGN E. DUEÑAS', p_position: 'Project Technical Staff II, UniFAST', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: '', n_position: ''},
        { BiometricID: '53', FirstName: 'Mona Pia', LastName: 'Galing', prepared: 'MONA PIA G. GALING', p_position: 'Project Technical Staff I - HRDS', reviewed: 'FRANCIS D. ILAGAN', r_position: 'Administrative Officer III - HRMO', noted: 'ALMA S. PATRON, MSW, LLB', n_position: 'Chief Administrative Officer'},
        { BiometricID: '55', FirstName: 'Christy Joy', LastName: 'Lajos', prepared: 'CHRISTY JOY E. LAJOS', p_position: 'Cashier Support Staff', reviewed: 'ANGEL A. ASIS, MM-CAR', r_position: 'Administrative Aide VI/Cashier', noted: 'ALMA SY-PATRON, MSW, LLB', n_position: 'Chief Administrative Officer'},
        { BiometricID: '57', FirstName: 'Orpheus', LastName: 'Cubillas', prepared: 'ORPHEUS A. CUBILLAS', p_position: 'Project Technical Staff II - Sports Coordinator', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '62', FirstName: 'Maelove', LastName: 'Dagohoy', prepared: 'MAELOVE I. DAGOHOY', p_position: 'Project Technical Staff III -  Accounting Unit', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'Accountant II', noted: 'ALMA S. PATRON, MSW, LLB', n_position: 'Chief Administrative Officer'},
        { BiometricID: '63', FirstName: 'JESIELYN MAE', LastName: 'ADO', prepared: 'JESIELYN MAE I. ADO', p_position: 'Project Technical Staff II -  Regional Statistical Focal Officer', reviewed: 'AMY C. SAAVEDRA, MSCA', r_position: 'Education Supervisor II - MIS Head', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '65', FirstName: 'Eljean Rose', LastName: 'Sande', prepared: 'ELJEAN ROSE U. SANDE', p_position: 'CSP Support Staff', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '66', FirstName: 'Ceaka Angel', LastName: 'Pacot', prepared: 'CEAKA ANGEL P. PACOT', p_position: 'Admin Staff', reviewed: 'REVELYN P. BRINA, MAED', r_position: 'Administrative Officer III', noted: 'GESELLE M. FRANCISCO, CPA', n_position: 'OIC - Chief Administrative Officer'},
        { BiometricID: '67', FirstName: 'Christopher', LastName: 'Hingpit', prepared: 'CHRISTOPHER A. HINGPIT', p_position: 'Project Technical Staff III', reviewed: '', r_position: '', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '68', FirstName: 'Marti Jose', LastName: 'Maban', prepared: 'MARTI JOSE M. MABAN', p_position: 'Project Technical Staff II', reviewed: 'CRISELDO C. CALINAWAN,PHD, PECE', r_position: 'Education Supervisor II', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '70', FirstName: 'Anilou', LastName: 'Cabarrubias', prepared: 'ANILOU G. CABARRUBIAS', p_position: 'CSP Support Staff', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},

        //REGULAR  EMPLOYEES
        { BiometricID: '1', FirstName: 'Alma', LastName: 'Patron', prepared: 'ALMA S. PATRON, MSW, LLB', p_position: 'Chief Administrative Officer', reviewed: 'GEORGE M. COLORADO, PHD, CESO III', r_position: 'Director IV'},
        { BiometricID: '2', FirstName: 'Geselle', LastName: 'Francisco', prepared: 'GESELLE G. FRANCISCO, CPA', p_position: 'Accountant II', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '3', FirstName: 'Francis', LastName: 'Ilagan', prepared: 'FRANCIS D. ILAGAN', p_position: 'Administrative Officer III', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '4', FirstName: 'Haemaccel', LastName: 'Palima', prepared: 'HAEMACEL I. PALIMA', p_position: 'Admin Aide III/Supply Officer', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '5', FirstName: 'Misael', LastName: 'Cabang', prepared: 'MISAEL S. CABANG', p_position: 'Admin Aide III', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '13', FirstName: 'Julia Felisa', LastName: 'Martinez', prepared: 'JULIA FELISA C. MARTINEZ, PHD, LPT', p_position: 'Chief Education Program Specialist', reviewed: 'GEORGE M. COLORADO, PHD, CESO III', r_position: 'Director IV'},
        { BiometricID: '26', FirstName: 'Angel', LastName: 'Asis', prepared: 'ANGEL A. ASIS,MM-CAR', p_position: 'Administrative Aide VI/HR', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '28', FirstName: 'Revelyn', LastName: 'Brina', prepared: 'REVELYN P. BRINA, MAED', p_position: 'Administrative Officer III', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '59', FirstName: 'Noeme', LastName: 'Mani', prepared: 'NOEME ROSE D. MANI, CPA, MM', p_position: 'Supervising Education Program Specialist', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '32', FirstName: 'Criseldo', LastName: 'Calinawan', prepared: 'ENGR. CRISELDO C. CALINAWAN, PHD', p_position: 'Education Supervisor II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '34', FirstName: 'Amy', LastName: 'Saavedra', prepared: 'AMY C. SAAVEDRA, MSCA', p_position: 'Education Supervisor II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '36', FirstName: 'Fred', LastName: 'Amihan', prepared: 'FREDERICK C. AMIHAN, PHD', p_position: 'Education Supervisor II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '37', FirstName: 'Rolyna', LastName: 'Trugillo', prepared: 'ROLYNA S. TRUGILLO, PHD', p_position: 'Education Supervisor II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '61', FirstName: 'Ever Joy', LastName: 'Rabadan', prepared: 'EVER JOY G. RABADAN, MSAS', p_position: 'Education Supervisor II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},

      ],
      chedEmployeesUnit: [
        { BiometricID: '8', FirstName: 'Kathleen', LastName: 'Castanares', division: 'ADMINISTRATIVE', unit_section: 'BUDGET'},
        { BiometricID: '9', FirstName: 'Sarah', LastName: 'Moron', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '14', FirstName: 'Calwin', LastName: 'Alegre', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '15', FirstName: 'Daisy', LastName: 'Grana', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '16', FirstName: 'May Trinity', LastName: 'Narca', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '17', FirstName: 'Alexis Dave', LastName: 'Sanmiguel', division: 'TECHNICAL', unit_section: 'MARITIME'},
        { BiometricID: '18', FirstName: 'Krizia Meijel', LastName: 'Mellijor', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '19', FirstName: 'Valerie', LastName: 'Vismanos', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '21', FirstName: 'Heidi', LastName: 'Paradero', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '22', FirstName: 'Jo', LastName: 'Orendain', division: 'ADMINISTRATIVE', unit_section: 'FRONT-DESK'},
        { BiometricID: '23', FirstName: 'Eleazar', LastName: 'Sumalinog', division: 'ADMINISTRATIVE', unit_section: 'GENERAL SERVICES'},
        { BiometricID: '30', FirstName: 'Ronan', LastName: 'Otaza', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '38', FirstName: 'Bill', LastName: 'Baconguis', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '39', FirstName: 'Christine', LastName: 'Escol', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '42', FirstName: 'Charles Jem', LastName: 'Montes', division: 'ADMINISTRATIVE', unit_section: 'PROCUREMENT'},
        { BiometricID: '43', FirstName: 'Trician Faye', LastName: 'Gozon', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '46', FirstName: 'Maria Mercedes', LastName: 'Rebita', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '51', FirstName: 'Gladys', LastName: 'Concha', division: 'ADMINISTRATIVE', unit_section: 'CHED-COA'},
        { BiometricID: '52', FirstName: 'Kershey Reign', LastName: 'Dueñas', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '53', FirstName: 'Mona Pia', LastName: 'Galing', division: 'ADMINISTRATIVE', unit_section: 'HR'},
        { BiometricID: '55', FirstName: 'Christy Joy', LastName: 'Lajos', division: 'ADMINISTRATIVE', unit_section: 'CASHIER SECTION'},
        { BiometricID: '57', FirstName: 'Orpheus', LastName: 'Cubillas', division: 'TECHNICAL', unit_section: 'CHED SPORTS UNIT'},
        { BiometricID: '65', FirstName: 'Eljean Rose', LastName: 'Sande', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '63', FirstName: 'Jesielyn Mae', LastName: 'Ado', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '66', FirstName: 'Ceaka Angel', LastName: 'Pacot', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '67', FirstName: 'Christopher', LastName: 'Hingpit', division: 'TECHNICAL', unit_section: 'LGSO'},
        { BiometricID: '68', FirstName: 'Marti Jose', LastName: 'Maban', division: 'TECHNICAL', unit_section: 'MARITIME'},
        { BiometricID: '70', FirstName: 'Anilou', LastName: 'Cabarrubias', division: 'TECHNICAL', unit_section: 'CSP'},


        //REGULAR EMPLOYEE
        { BiometricID: '1', FirstName: 'Alma', LastName: 'Patron', division: 'ADMINISTRATIVE', unit_section: 'ADMIN'},
        { BiometricID: '2', FirstName: 'Geselle', LastName: 'Francisco', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '3', FirstName: 'Francis', LastName: 'Ilagan', division: 'ADMINISTRATIVE', unit_section: 'HR'},
        { BiometricID: '4', FirstName: 'Haemaccel', LastName: 'Palima', division: 'ADMINISTRATIVE', unit_section: 'PROCUREMENT'},
        { BiometricID: '5', FirstName: 'Misael', LastName: 'Cabang', division: 'ADMINISTRATIVE', unit_section: 'PROCUREMENT'},
        { BiometricID: '13', FirstName: 'Julia Felisa', LastName: 'Martinez', division: 'TECHNICAL', unit_section: 'MONITORING AND EVALUATION'},
        { BiometricID: '26', FirstName: 'Angel', LastName: 'Asis', division: 'ADMINISTRATIVE', unit_section: 'BUDGET'},
        { BiometricID: '28', FirstName: 'Revelyn', LastName: 'Brina', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '59', FirstName: 'Noeme', LastName: 'Mani', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '32', FirstName: 'Criseldo', LastName: 'Calinawan', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '34', FirstName: 'Amy', LastName: 'Saavedra', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '36', FirstName: 'Fred', LastName: 'Amihan', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '37', FirstName: 'Rolyna', LastName: 'Trugillo', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '61', FirstName: 'Ever Joy', LastName: 'Rabadan', division: 'TECHNICAL', unit_section: 'MPSD'},

      ]
    }
  },
  mounted() {
   this.getWeeklyPlan();
  },
  methods: {

  resetPlan() {
    this.ar_plan = {
      plan: '',
      from_date_plan: '',
      to_date_plan: ''
    }
  },
  addPlan() {
    $('#add-plan').modal('show');
  },
  printAR() {
     this.$htmlToPaper('ar');
  },
  openUpdatePlan(item) {
    this.editPlan = { ...item };
    $('#edit-plan').modal('show');
    console.log("plann", this.editPlan);
  },
  updatePlan(planbyId) {
    $('#edit-plan').modal('show');
    this.editPlan = {...planbyId}
    console.log("plann", this.editPlan);
  },
  submitUpdatePlan(plan_id) {
      this.loading = true;
      let formData = new FormData();
      formData.append('plan', this.editPlan.plan);
      formData.append('plan_id', plan_id);

      axios
        .post('api/edit-plan', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          $('#edit-plan').modal('hide');
          this.getWeeklyPlan();
          this.loading = false;

            // if(this.from && this.to) {
            //     this.searchLogs(this.from, this.to);
            // } else {
            //     this.getWeeklyPlan();
            // }
        })
        .catch((response) => {

          this.loading = false;
          console.log(response);
          alert('Something went wrong!');
          this.loading = false;

        });
  },
  deletePlan(plan_id) {
      this.loading1 = true;
      let formData = new FormData();
      formData.append('plan_id', plan_id);
     axios
        .post('api/delete-plan', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          $('#edit-plan').modal('hide');
          this.loading1 = false;
          this.getWeeklyPlan();
        })
        .catch((response) => {
          console.log(response);
          this.loading1 = false;
          alert('Something went wrong!');
        });
  },
    previewAR() {
     this.preview = false;
     this.printPreview = true;
    },
    unpreviewAR() {
     this.preview = false;
     this.printPreview = false;

    },
  reference() {

      let d = moment().format('L');
      let t = moment().format('LTS');

      return d + ' ' + t; 
   },
   get_accomplishmentsFunc(date) {

     const result = this.get_accomplishments.filter((ac) => ac.date_of_work === date);

     return result;
   },
   getDivision(id) {
    let division = '';
    this.chedEmployeesUnit.forEach((cosEmployee) => {
          if (cosEmployee.BiometricID == id) {
            division = cosEmployee.division;
          }
        });

        return division;
   },
   getSection(id) {
    let section = '';
    this.chedEmployeesUnit.forEach((cosEmployee) => {
          if (cosEmployee.BiometricID == id) {
            section = cosEmployee.unit_section;
          }
        });

        return section;
   },
   getPreparedBy(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.prepared;
          }
        });

        return prepared;
    },
    getPreparedPosition(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.p_position;
          }
        });

        return prepared;
    },
    getReviewed(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.reviewed;
          }
        });

        return prepared;
    },
    getReviewedPosition(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.r_position;
          }
        });

        return prepared;
    },
    getNoted(id) {

    let noted = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            noted = chedEmployee.noted;
          }
        });

        return noted;
    },
    getNotedPosition(id) {

    let noted = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            noted = chedEmployee.n_position;
          }
        });

        return noted;
    },
   formatDate(date) {
      return moment
        .utc(date, "YYYY-MM-D")
        .locale("en-us")
        .local()
        .format("MMM D YYYY dddd");
        
    },
    submitPlan() {
      this.loading = true;
      let formData = new FormData();
      formData.append('plan', this.ar_plan.plan);
      axios
        .post('api/add-plan', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          $('#add-plan').modal('hide');
          this.loading = false;
          this.resetPlan();
          this.getWeeklyPlan();
            // if(this.from && this.to) {
            //     this.searchLogs(this.from, this.to);
            // } else {
            //     this.getWeeklyPlan();
            // }
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;

          alert('Something went wrong!');
        });
    },
    async getWeeklyPlan() {
      try {
        const response = await axios.get('api/get-weekly-plan')
            .then((response) => {
             this.get_weekly_plans = response.data.get_weekly_plans;
             console.log("get_weekly_plan", this.get_weekly_plans);
       })
      } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
    getDate() {
      let m = this.from; // get the month
      let f = this.from; // get date from
      let t = this.to; // get date to

      let month =  String(m[5]) + String(m[6]);
      let year = String(m[0]) + String(m[1]) + String(m[2]) + String(m[3]);
      let dayTo = "";
      let dayFrom = "";
      
      this.months = month;
      this.years = year;

      if(this.to) {
        dayTo = String(t[8]) + String(t[9]);
      }
      if(this.from) {
        dayFrom = String(f[8]) + String(f[9]);
      }

      let d = new Date();

      if(month === '01') {
        return "January" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '02') {
        return "February" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '03') {
        return "March" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '04') {
        return "April" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '05') {
        return "May" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '06') {
        return "June" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '07') {
        return "July" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '08') {
        return "August" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '09') {
        return "September" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '10') {
        return "October" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '11') {
        return "November" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '12') {
        return "December" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else {
        return "";
      } 

    },
    searchLogs() {
      this.loading = true;

        setTimeout(
          function() {
            this.preview = true;
            this.loading = false;
          }
          .bind(this)
        , 1000);

    },
  
  }
}
</script>

<style>

</style>